@import "styles/media";

.navigation {
    padding: 14px 28px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    width: 90vw;
    height: 67px;
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    margin: 30px auto auto;
    position: sticky;
    top: 20px !important;

    .navItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .logo {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .logoLink {
        display: flex;
    }

    .linksWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .navLink {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    & .link,
    .dropDownItem {
        font-size: 1rem;
        letter-spacing: -0.02rem;
        line-height: 140%;
        font-family: Sohne-Regular;
        font-style: normal;
        font-weight: 400;
    }

    .dropDownItem:active {
        background-color: var(--MAIN_GREEN);
    }

    .mobile {
        display: none;

        @include for-mobile {
            display: flex;
        }
    }
}

.header {
    color: var(--BASE-WHITE);

    @include for-mobile {
        display: none !important;
    }
}
