.page-content {
  margin: 0 6%;
}

.row {
  display: flex;
}

.text-color-main-green {
  color: var(--MAIN_GREEN);
}

.text-color-gray-50 {
  color: var(--GRAY_50);
}

.text-color-gray-100 {
  color: var(--GRAY_100);
}

.text-color-gray-300 {
  color: var(--GRAY_300);
}

.text-color-gray-400 {
  color: var(--GRAY_400);
}

.text-color-gray-500 {
  color: var(--GRAY_500);
}

.text-color-gray-700 {
  color: var(--GRAY_700);
}

.text-color-gray-900 {
  color: var(--GRAY_900);
}

.text-500-14px {
  font-family: 'Sohne-Bold';
  font-weight: 500;
  font-size: 14px;
}

.text-500-18px {
  font-family: 'Sohne-Bold';
  font-weight: 500;
  font-size: 18px;
}

.text-400-16px-regular {
  font-family: 'Sohne-Regular';
  font-weight: 400;
  font-size: 16px;
}

.text-700-20px {
  font-family: 'Sohne-Bold';
  font-weight: 700;
  font-size: 20px;
}

.change-cursor {
  cursor: pointer;
}

.martian-mono {
  font-family: 'Sohne-Regular';
  font-style: normal;
}

.space-between {
  justify-content: space-between;
}

.custom-button:hover {
  opacity: 0.9;
}
