@import "Fonts.css";
@import "Colors.css";
@import "Typography.css";
@import "Spacing.css";

*,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

body {
  background-color: rgb(230, 230, 230);
  font-family: "Times New Roman", Times, serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.oone-navbar {
  padding: 14px 28px 14px 28px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 90vw;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  margin: auto;
  margin-top: 30px;
}

a:hover {
  color: var(--INDIGO_500_HOVERED);
}

.oone-clickable {
  cursor: pointer;
}

.oone-hover:hover,
.oone-hover a:hover,
.oone-hover:hover svg path {
  fill: var(--MAIN_GREEN) !important;
  color: var(--MAIN_GREEN) !important;
}

input:focus-visible {
  outline: none;
}

.mobile {
  display: none !important;
}

.desktop {
  display: flex !important;
}

hr {
  color: var(--GRAY_100);
  opacity: 1;
  margin: 0;
}

.oone-services-bar {
  padding: 1% 7% 0 7%;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.justify-content-space-between {
  justify-content: space-between;
}

.no-wrap {
  white-space: nowrap;
}

.no-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
