

/* DEPRECATED STYLING */

.txt-xs-regular {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 150%;
}

.txt-xs-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
}

.txt-xs-semibold {
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 150%;
}

.txt-sm-semibold {
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 180%;
}

.txt-l-semibold {
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
}

.txt-xl-semibold {
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 150%;
}

.txt-sm-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 180%;
}

.txt-sm-regular {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 180%;
}

.txt-md-regular {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
}

.txt-md-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
}

.txt-xl-medium {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
}

.txt-xl-regular {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
}

.txt-l-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
}

.txt-l-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 150%;
}

.txt-xl-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
}

.txt-xl-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 150%;
}

.txt-xxl-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 180%;
}

.txt-xxl-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 180%;
}

.txt-xxl-regular {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 180%;
}

.txt-xxl-bold-hubot-sans {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 180%;
}

.txt-xxxxl-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 127%;
}

.txt-xxxxl-semibold {
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 127%;
}

.txt-xxxxl-light {
  font-family: 'Sohne-Light';
  font-style: normal;
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 127%;
}

.txt-mega-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 6.25rem;
  line-height: 127%;
}

.txt-mega-small-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 127%;
}

.txt-mega-tiny-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 127%;
}

.txt-mega-small-semibold {
  font-family: 'Sohne-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 4rem;
  line-height: 127%;
}

.txt-mega-small-medium {
  font-family: 'Sohne-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 4rem;
  line-height: 127%;
}

.txt-6xl-bold {
  font-family: 'Sohne-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 127%;
}

.txt-xs-regular-martian-mono {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: 0.02rem;
}

.txt-sm-regular-martian-mono {
  font-family: 'Sohne-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 143%;
  letter-spacing: 0.02rem;
}

/* NEW STYLING */

/* ---------------------------------------------------------------------------------
    FONT FAMILIES
--------------------------------------------------------------------------------- */

/* Sans-Serif Regular */
.txt-serif-regular {
  font-family: 'Sohne-Regular';
  font-weight: 400;
  font-style: normal;
}

/* Sans-Serif Medium */
.txt-serif-medium {
  font-family: 'Sohne-Medium';
  font-weight: 500;
  font-style: normal;
}

/* Mono Light */
.txt-mono-light {
  font-family: 'BasisGrotesqueMonoPro-Light';
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0 !important;
}

/* Mono Regular */
.txt-mono-regular {
  font-family: 'BasisGrotesqueMonoPro-Regular';
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0 !important;
}

/* ---------------------------------------------------------------------------------
    FONT SIZES
--------------------------------------------------------------------------------- */

/* 12 px */
.txt-sz-xs {
  font-size: .75rem; 
  line-height: 150%;
  letter-spacing: -0.02rem;
}

/* 14 px */
.txt-sz-s {
  font-size: .875rem;
  line-height: 150%;
  letter-spacing: -0.02rem;
}

/* 16 px */
.txt-sz-m {
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.02rem;
}

/* 18 px */
.txt-sz-l {
  font-size: 1.125rem;
  line-height: 150%;
  letter-spacing: -0.02rem;
}

/* 20 px */
.txt-sz-xl {
  font-size: 1.25rem;
  line-height: 150%;
  letter-spacing: -0.02rem;
}

/* 22 px */
.txt-sz-xxl {
  font-size: 1.375rem;
  line-height: 150%;
  letter-spacing: -0.02rem;
}

/* 40 px */
.txt-sz-big-s {
  font-size: 2.5rem;
  line-height: 130%;
  letter-spacing: -0.03rem;
}

/* 48 px */
.txt-sz-big-m {
  font-size: 3rem;
  line-height: 130%;
  letter-spacing: -0.03rem;
}

/* 64 px */
.txt-sz-big-l {
  font-size: 4rem;
  line-height: 130%;
  letter-spacing: -0.03rem;
}

/* 76 px */
.txt-sz-big-xl {
  font-size: 4.75rem;
  line-height: 130%;
  letter-spacing: -0.03rem;
}

/* ---------------------------------------------------------------------------------
    FONT LINE HEIGHT
--------------------------------------------------------------------------------- */

.txt-lh-80 {
  line-height: 80% !important;
}

.txt-lh-90 {
  line-height: 90% !important;
}

.txt-lh-100 {
  line-height: 100% !important;
}

.txt-lh-110 {
  line-height: 110% !important;
}

.txt-lh-120 {
  line-height: 120% !important;
}

.txt-lh-130 {
  line-height: 130% !important;
}

.txt-lh-140 {
  line-height: 140% !important;
}

.txt-lh-150 {
  line-height: 150% !important;
}

.txt-lh-200 {
  line-height: 200% !important;
}


@media screen and (max-width: 1024px) {
  .txt-sz-big-l{
    font-size: 2.15rem;
  }

  .txt-sz-xl {
    font-size: 1.07rem;
  }

  .txt-sz-big-xl {
    font-size: 2rem;
  }
  

  .oone-footer-default-column {
    width: 100%;
  }

  .oone-footer-right-column {
    width: 100%;
  }
}